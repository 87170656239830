import Vue from 'vue'
import Vuex from 'vuex'
import { axiosGet } from '@/utils/axios'
import jwt_decode from 'jwt-decode'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    loadingOverlay: false,
    zindexLeftMenu: 1,
    zindexOverlay: 1,
    anggotaSelected: {},
    barangSelected: {},
  },
  getters: {
    loggedIn(state) {
      if (state.token === 'null' || state.token == null) return false
      if (state.token === 'undefined') return false

      return true
    },
    user(state) {
      const tokenDecode = jwt_decode(state.token)

      return tokenDecode.data
    },
    token(state) {
      return state.token
    },
    loadingOverlay(state) {
      return state.loadingOverlay
    },
    zindexOverlay(state) {
      return state.zindexOverlay
    },
    zindexLeftMenu(state) {
      return state.zindexLeftMenu
    },
    anggotaSelected(state) {
      return state.anggotaSelected
    },
    barangSelected(state) {
      return state.barangSelected
    },
  },
  mutations: {
    setDataLogin(state, data) {
      state.token = data.token
    },
  },
  actions: {
    login({ commit }, data) {
      commit('setDataLogin', data)
    },
    logout({ commit }, message = '') {
      axiosGet('Auth/removeToken')
      alert(message + 'Anda keluar dari sistem')
      window.location.reload()
    },
    async getToken({ commit }) {
      const data = await axiosGet('Auth/getToken')
      if (data.code === 200) {
        const dataLogin = {
          token: data.token,
        }
        commit('setDataLogin', dataLogin)
      }
    },
    loading(context, status) {
      if (status == true) {
        context.state.loadingOverlay = true
        context.state.zindexLeftMenu = 1001
        context.state.zindexOverlay = 1000
      } else {
        context.state.loadingOverlay = false
        context.state.zindexLeftMenu = 1
        context.state.zindexOverlay = 1
      }
    },
  },
  modules: {},
})
