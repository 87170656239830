import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import VueHtmlToPaper from 'vue-html-to-paper'
import Vue from 'vue'
import Axios from 'axios'
import IdleVue from 'idle-vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.prototype.$http = Axios
Vue.config.productionTip = false

const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3600 * 1000, // 1jam
  // idleTime: 3 * 1000, // 1jam
  startAtIdle: false,
})

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    process.env.VUE_APP_MAINURL + '/print.css', // <- inject here
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
Vue.use(VueHtmlToPaper, options)
Vue.mixin({
  methods: {
    formatDate(input) {
      if (input === undefined || input === null) return input
      const datePart = input.match(/\d+/g)
      const year = datePart[0].substring(0, 4)
      const month = datePart[1]
      const day = datePart[2]

      return `${day}-${month}-${year}`
    },
    formatDateTime(input) {
      if (input === undefined || input === null) return input

      const datePart = input.match(/\d+/g)
      const year = datePart[0].substring(0, 4) // get only two digits
      const month = datePart[1]
      const day = datePart[2]
      const waktu = input.slice(11, 19)

      return `${day}-${month}-${year} ${waktu}`
    },
    formatMoney(input) {
      return new Intl.NumberFormat(['id']).format(input)
    },
  },
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
