<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
    :style="'z-index: ' + zindexLeftMenu"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header items-center pt-2">
      <router-link to="/" class="align-center text-decoration-none">
        <v-img
          v-if="mode == 'PRODUCTION'"
          :src="require('@/assets/images/logos/logo.png')"
          max-width="250px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition v-else>
          <v-alert type="warning" style="width: 100%">{{ mode }}</v-alert>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <!-- ADMIN -->
    <v-list v-if="user.role == 'ADMIN'" expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link title="Dashboard" :to="{ name: 'dashboard' }" :icon="icons.mdiHomeOutline"></nav-menu-link>

      <nav-menu-group title="Transaksi" :icon="icons.mdiCogOutline">
        <nav-menu-link title="Tagihan" :to="{ name: 'tagihan' }"></nav-menu-link>
        <nav-menu-link title="Tagihan 2" :to="{ name: 'tagihan2' }"></nav-menu-link>
        <nav-menu-link title="SHU" :to="{ name: 'shu' }"></nav-menu-link>
        <nav-menu-link title="Sembako" :to="{ name: 'sembako' }"></nav-menu-link>
        <nav-menu-link title="Ambil Tabungan" :to="{ name: 'ambiltabungan' }"></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group title="Master & Pengaturan" :icon="icons.mdiFileOutline">
        <nav-menu-link title="Anggota" :to="{ name: 'master-anggota' }"></nav-menu-link>
        <nav-menu-link title="Pengguna" :to="{ name: 'master_user' }"></nav-menu-link>
        <nav-menu-link title="Pengaturan" :to="{ name: 'pengaturan' }"></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group title="Pinjaman" :icon="icons.mdiCogOutline">
        <nav-menu-link title="Program Pinjaman" :to="{ name: 'setting-pinjaman' }"></nav-menu-link>
        <nav-menu-link title="Pengajuan Pinjaman" :to="{ name: 'pengajuan-pinjaman' }"></nav-menu-link>
        <nav-menu-link title="Daftar Pinjaman" :to="{ name: 'pinjaman' }"></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group title="Simpanan" :icon="icons.mdiAccountCogOutline">
        <nav-menu-link title="Jenis Simpanan" :to="{ name: 'setting-simpanan' }"></nav-menu-link>
        <nav-menu-link title="Daftar Simpanan" :to="{ name: 'daftar-simpanan' }"></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group title="Tabungan" :icon="icons.mdiAccountCogOutline">
        <nav-menu-link title="Program Tabungan" :to="{ name: 'jenis-tabungan' }"></nav-menu-link>
        <nav-menu-link title="Daftar Tabungan" :to="{ name: 'daftar-tabungan' }"></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group title="Arisan" :icon="icons.mdiAccountCogOutline">
        <nav-menu-link title="Pengajuan Grup" :to="{ name: 'arisan_pengajuan' }"></nav-menu-link>
        <nav-menu-link title="Grup" :to="{ name: 'arisan_grup' }"></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group title="Toko Online" :icon="icons.mdiAccountCogOutline">
        <nav-menu-link title="Pengaturan Cicilan" :to="{ name: 'setting_cicilan' }"></nav-menu-link>
        <nav-menu-link title="Barang" :to="{ name: 'barang_dagangan' }"></nav-menu-link>
        <nav-menu-link title="Permintaan Barang" :to="{ name: 'permintaan_barang' }"></nav-menu-link>
        <nav-menu-link title="Transaksi Barang" :to="{ name: 'transaksi_barang' }"></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group title="Akuntansi" :icon="icons.mdiAccountCogOutline">
        <nav-menu-link title="Akun" :to="{ name: 'master-akun' }"></nav-menu-link>
        <nav-menu-link title="Tutup Buku" :to="{ name: 'tutup-buku' }"></nav-menu-link>
        <nav-menu-link title="Jurnal" :to="{ name: 'jurnal-umum' }"></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group title="Laporan" :icon="icons.mdiAccountCogOutline">
        <nav-menu-link title="Anggota" :to="{ name: 'laporan_anggota' }"></nav-menu-link>
        <nav-menu-link title="Jatuh Tempo" :to="{ name: 'laporan_jatuhtempo' }"></nav-menu-link>
        <nav-menu-link title="Angsuran" :to="{ name: 'laporan_angsuran' }"></nav-menu-link>
        <nav-menu-link title="Bunga Tabungan" :to="{ name: 'laporan_bungatabungan' }"></nav-menu-link>
        <!-- <nav-menu-link title="Kas Anggota" :to="{ name: 'laporan_anggota' }"></nav-menu-link> -->
        <nav-menu-link title="Jurnal Umum" :to="{ name: 'lap-jurnal' }"></nav-menu-link>
        <nav-menu-link title="Buku Besar" :to="{ name: 'lap-bukubesar' }"></nav-menu-link>
        <nav-menu-link title="Neraca Saldo" :to="{ name: 'lap-neraca' }"></nav-menu-link>
        <nav-menu-link title="Laba Rugi" :to="{ name: 'lap-labarugi' }"></nav-menu-link>
      </nav-menu-group>

      <!-- <nav-menu-section-title title="USER INTERFACE"></nav-menu-section-title> -->

      <!-- <nav-menu-group title="Pages" :icon="icons.mdiFileOutline">
        <nav-menu-link title="Login" :to="{ name: 'pages-login' }" target="_blank"></nav-menu-link>
        <nav-menu-link title="Register" :to="{ name: 'pages-register' }" target="_blank"></nav-menu-link>
        <nav-menu-link title="Error" :to="{ name: 'error-404' }" target="_blank"></nav-menu-link>
      </nav-menu-group> -->

      <!-- <nav-menu-link title="Typography" :to="{ name: 'typography' }" :icon="icons.mdiAlphaTBoxOutline"></nav-menu-link>
      <nav-menu-link title="Icons" :to="{ name: 'icons' }" :icon="icons.mdiEyeOutline"></nav-menu-link>
      <nav-menu-link title="Cards" :to="{ name: 'cards' }" :icon="icons.mdiCreditCardOutline"></nav-menu-link>
      <nav-menu-link title="Tables" :to="{ name: 'simple-table' }" :icon="icons.mdiTable"></nav-menu-link>
      <nav-menu-link title="Form Layouts" :to="{ name: 'form-layouts' }" :icon="icons.mdiFormSelect"></nav-menu-link> -->
    </v-list>

    <!-- HRD -->
    <v-list v-if="user.role == 'HRD' || user.role == 'PENGAWAS'" expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link title="Dashboard" :to="{ name: 'dashboard' }" :icon="icons.mdiHomeOutline"></nav-menu-link>
      <nav-menu-group title="Pinjaman" :icon="icons.mdiCogOutline">
        <nav-menu-link title="Pengajuan Pinjaman" :to="{ name: 'pengajuan-pinjamanh' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="Laporan" :icon="icons.mdiAccountCogOutline">
        <nav-menu-link title="Anggota" :to="{ name: 'laporan_anggota' }"></nav-menu-link>
        <nav-menu-link title="Jatuh Tempo" :to="{ name: 'laporan_jatuhtempo' }"></nav-menu-link>
        <!-- <nav-menu-link title="Kas Anggota" :to="{ name: 'laporan_anggota' }"></nav-menu-link> -->
        <nav-menu-link title="Jurnal Umum" :to="{ name: 'lap-jurnal' }"></nav-menu-link>
        <nav-menu-link title="Buku Besar" :to="{ name: 'lap-bukubesar' }"></nav-menu-link>
        <nav-menu-link title="Neraca Saldo" :to="{ name: 'lap-neraca' }"></nav-menu-link>
        <nav-menu-link title="Laba Rugi" :to="{ name: 'lap-labarugi' }"></nav-menu-link>
      </nav-menu-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiCogOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

const mode = process.env.VUE_APP_MODE

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  data() {
    return {
      mode: mode,
    }
  },
  computed: {
    zindexLeftMenu() {
      return this.$store.getters.zindexLeftMenu
    },
    user() {
      return this.$store.getters.user
    },
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiCogOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
