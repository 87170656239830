<template>
  <v-app>
    <ModalIdle v-if="isIdle && loggedIn" />

    <vertical-nav-menu class="d-print-none" :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar class="d-print-none" app flat absolute color="transparent">
      <!-- <div class="boxed-container w-full"> -->
      <div class="w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <!-- <v-text-field
            rounded
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field> -->
          <v-alert v-if="mode != 'PRODUCTION'" type="warning" style="width: 100%">{{ mode }}</v-alert>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <theme-switcher></theme-switcher>
          <!-- <v-btn icon small class="ms-3">
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container">
        <slot></slot>
      </div>
    </v-main>
    <v-overlay :style="'z-index: ' + zindexOverlay" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import ModalIdle from './components/ModalIdle.vue'

const mode = process.env.VUE_APP_MODE

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    ModalIdle,
  },
  data() {
    return {
      show: true,
      mode: mode,
    }
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
      },
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn
    },
    user() {
      return this.$store.getters.user
    },
    overlay() {
      return this.$store.getters.loadingOverlay
    },
    zindexOverlay() {
      return this.$store.getters.zindexOverlay
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
