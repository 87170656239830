import Vue from 'vue'
import VueRouter from 'vue-router'
import jwt_decode from "jwt-decode"
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // MASTER
  {
    path: '/master-anggota',
    name: 'master-anggota',
    component: () => import('@/views/master/MasterAnggota.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },

  {
    path: '/anggota/:id_anggota',
    name: 'detailanggota',
    component: () => import('@/views/master/DetailAnggota.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/master_user',
    name: 'master_user',
    component: () => import('@/views/master/MasterUser.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/pengaturan',
    name: 'pengaturan',
    component: () => import('@/views/master/Pengaturan.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },

  // SIMPANAN
  {
    path: '/setting-simpanan',
    name: 'setting-simpanan',
    component: () => import('@/views/simpanan/SettingSimpanan.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/daftar-simpanan',
    name: 'daftar-simpanan',
    component: () => import('@/views/simpanan/DaftarSimpanan.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/simpanan/:id_anggota',
    name: 'simpanan',
    component: () => import('@/views/simpanan/Simpanan.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/tagihan',
    name: 'tagihan',
    component: () => import('@/views/tagihan/Tagihan.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/tagihan2',
    name: 'tagihan2',
    component: () => import('@/views/tagihan/Tagihan2.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },

  // TABUNGAN
  {
    path: '/jenis-tabungan',
    name: 'jenis-tabungan',
    component: () => import('@/views/tabungan/JenisTabungan.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/daftar-tabungan',
    name: 'daftar-tabungan',
    component: () => import('@/views/tabungan/DaftarTabungan.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/detail-tabungan/:id_tabungan',
    name: 'detail-tabungan',
    component: () => import('@/views/tabungan/DetailTabungan.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },

  // PINJAMAN
  {
    path: '/setting-pinjaman',
    name: 'setting-pinjaman',
    component: () => import('@/views/pinjaman/SettingPinjaman.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/pengajuan-pinjaman',
    name: 'pengajuan-pinjaman',
    component: () => import('@/views/pinjaman/Pengajuan.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/pengajuan-pinjamanh',
    name: 'pengajuan-pinjamanh',
    component: () => import('@/views/pinjaman/PengajuanHRD.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['HRD', 'PENGAWAS'],
    },
  },
  {
    path: '/pinjaman',
    name: 'pinjaman',
    component: () => import('@/views/pinjaman/Pinjaman.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/angsuran/:id_pinjaman',
    name: 'angsuran',
    component: () => import('@/views/pinjaman/Angsuran.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },

  // ARISAN
  {
    path: '/arisan_grup',
    name: 'arisan_grup',
    component: () => import('@/views/arisan/Grup.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/arisan_anggota_grup/:id/:nama',
    name: 'arisan_anggota_grup',
    component: () => import('@/views/arisan/GrupAnggota.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/arisan_pengajuan',
    name: 'arisan_pengajuan',
    component: () => import('@/views/arisan/PengajuanGrup.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },

  // TOKO ONLINE
  {
    path: '/setting_cicilan',
    name: 'setting_cicilan',
    component: () => import('@/views/toko/SettingCicilan.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/barang_dagangan',
    name: 'barang_dagangan',
    component: () => import('@/views/master/MasterBarang.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/permintaan_barang',
    name: 'permintaan_barang',
    component: () => import('@/views/toko/PermintaanBarang.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/transaksi_barang',
    name: 'transaksi_barang',
    component: () => import('@/views/toko/TransaksiBarang.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  // {
  //   path: '/angsuran/:id_pinjaman',
  //   name: 'angsuran',
  //   component: () => import('@/views/pinjaman/Angsuran.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     userCanAccess: ['ADMIN'],
  //   },
  // },

  {
    path: '/master-akun',
    name: 'master-akun',
    component: () => import('@/views/akuntansi/MasterAkun.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/jurnal-umum',
    name: 'jurnal-umum',
    component: () => import('@/views/akuntansi/JurnalUmum.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/tutup-buku',
    name: 'tutup-buku',
    component: () => import('@/views/akuntansi/TutupBuku.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/laporan-anggota',
    name: 'laporan_anggota',
    component: () => import('@/views/laporan/Anggota.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: [
        'ADMIN',
        'HRD',
        'PENGAWAS',
      ],
    },
  },
  // {
  //   path: '/laporan_kasanggota',
  //   name: 'laporan_kasanggota',
  //   component: () => import('@/views/laporan/KasAnggota.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     userCanAccess: ['ADMIN'],
  //   },
  // },
  {
    path: '/laporan-jatuhtempo',
    name: 'laporan_jatuhtempo',
    component: () => import('@/views/laporan/JatuhTempo.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: [
        'ADMIN',
        'HRD',
        'PENGAWAS',
      ],
    },
  },
  {
    path: '/laporan-angsuran',
    name: 'laporan_angsuran',
    component: () => import('@/views/laporan/Angsuran.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: [
        'ADMIN',
        'HRD',
        'PENGAWAS',
      ],
    },
  },
  {
    path: '/laporan-bungatabungan',
    name: 'laporan_bungatabungan',
    component: () => import('@/views/laporan/LapBungaTabungan.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: [
        'ADMIN',
        'HRD',
        'PENGAWAS',
      ],
    },
  },
  {
    path: '/lap-jurnal',
    name: 'lap-jurnal',
    component: () => import('@/views/laporan/LapJurnalUmum.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: [
        'ADMIN',
        'HRD',
        'PENGAWAS',
      ],
    },
  },
  {
    path: '/lap-bukubesar',
    name: 'lap-bukubesar',
    component: () => import('@/views/laporan/LapBukuBesar.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: [
        'ADMIN',
        'HRD',
        'PENGAWAS',
      ],
    },
  },
  {
    path: '/lap-neraca',
    name: 'lap-neraca',
    component: () => import('@/views/laporan/LapNeraca.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: [
        'ADMIN',
        'HRD',
        'PENGAWAS',
      ],
    },
  },
  {
    path: '/lap-labarugi',
    name: 'lap-labarugi',
    component: () => import('@/views/laporan/LapLabaRugi.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: [
        'ADMIN',
        'HRD',
        'PENGAWAS',
      ],
    },
  },
  // TRANSAKSI
  {
    path: '/shu',
    name: 'shu',
    component: () => import('@/views/transaksi/Shu.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/sembako',
    name: 'sembako',
    component: () => import('@/views/transaksi/Sembako.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },
  {
    path: '/ambil-tabungan',
    name: 'ambiltabungan',
    component: () => import('@/views/transaksi/AmbilTabungan.vue'),
    meta: {
      requiresAuth: true,
      userCanAccess: ['ADMIN'],
    },
  },

  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      requiresAuth: true,
      // userCanAccess: ['ADMIN']
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },

  //////////////////////////////////

  // {
  //   path: '/pages/register',
  //   name: 'pages-register',
  //   component: () => import('@/views/pages/Register.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  // {
  //   path: '/typography',
  //   name: 'typography',
  //   component: () => import('@/views/typography/Typography.vue'),
  // },
  // {
  //   path: '/icons',
  //   name: 'icons',
  //   component: () => import('@/views/icons/Icons.vue'),
  // },
  // {
  //   path: '/cards',
  //   name: 'cards',
  //   component: () => import('@/views/cards/Card.vue'),
  // },
  // {
  //   path: '/simple-table',
  //   name: 'simple-table',
  //   component: () => import('@/views/simple-table/SimpleTable.vue'),
  // },
  // {
  //   path: '/form-layouts',
  //   name: 'form-layouts',
  //   component: () => import('@/views/form-layouts/FormLayouts.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (store.getters.loggedIn === false) {
    await store.dispatch('getToken')
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.token == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      })
    } else {
      const tokenDecoded = jwt_decode(store.state.token)
      const user = tokenDecoded.data
      if (to.matched.some(record => record.meta.userCanAccess)) {
        if (to.meta.userCanAccess.includes(user.role)) {
          next()
        } else {
          next({ name: 'dashboard' })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (store.state.token == null) {
      next()
    } else {
      next({ name: 'dashboard' })
    }
  } else {
    next()
  }
})

export default router
