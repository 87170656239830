import axios from 'axios'
import store from '../store'

axios.defaults.withCredentials = true
const apiRoot = process.env.VUE_APP_APIROOT

export function axiosPost(url, formPost) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiRoot}/${url}`, formPost)
      .then(response => {
        if (response.data.code === 401) {
          this.$store.dispatch('logout', 'Session berakhir!')
        } else if (response.data.code === 200) {
          resolve(response.data)
        } else {
          resolve(response.data)
        }
      })
      .catch(error => {
        resolve(error)
      })
  })
}
export function axiosPostAuth(url, formPost) {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: store.state.token,
      'Content-Type': 'multipart/form-data',
    }
    axios
      .post(`${apiRoot}/${url}`, formPost, { headers })
      .then(response => {
        if (response.data.code === 401) {
          this.$store.dispatch('logout', 'Session berakhir!')
        } else if (response.data.code === 200) {
          resolve(response.data)
        } else {
          resolve(response.data)
        }
      })
      .catch(error => {
        resolve(error)
      })
  })
}
export function axiosGet(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiRoot}/${url}`, {})
      .then(response => {
        if (response.data.code === 401) {
          this.$store.dispatch('logout', 'Session berakhir!')
        } else if (response.data.code === 200) {
          resolve(response.data)
        } else {
          resolve(response.data)
        }
      })
      .catch(error => {
        resolve(error)
      })
  })
}
export function axiosGetAuth(url) {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: store.state.token,
    }
    axios
      .get(`${apiRoot}/${url}`, { headers })
      .then(response => {
        if (response.data.code === 401) {
          this.$store.dispatch('logout', 'Session berakhir!')
        } else if (response.data.code === 200) {
          resolve(response.data)
        } else {
          resolve(response.data)
        }
      })
      .catch(error => {
        resolve(error)
      })
  })
}
